export function coursePackagesTableHead () {
  return [
    {
      text: '№',
      align: 'start',
      sortable: false,
      value: 'index'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      sortable: false,
      text: 'Actions',
      value: 'actions'
    }
  ]
}
