import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validation from '@/mixins/validation'

import Draggable from 'vuedraggable'

import { coursePackagesTableHead } from '@/modules/courses/helpers/packages'

export default {
    name        : 'courses-packages',
    mixin       : [mixins, course, validation],
    data () {
        return {
            payload         : {
                name            : '',
                crmId           : '',
                isDip           : false,
                sort            : '',
                zohoDealId      : '',
                zohoProductName : ''
            },
            packages        : [],
            valid           : true,
            showAction      : false,
            showPopupDelete : false,
            removingItem    : null,
            typeDialog      : 'create'
        }
    },
    components  : {
        Draggable
    },
    watch       : {
        'list' () {
            this.packages = this.list
        }
    },
    computed: {
        ...mapGetters({
            list            : 'coursesPackages/list',
            profile         : 'profile/profile',
            setting         : 'courses/setting',
            listLoading     : 'coursesPackages/listLoading',
            requestLoading  : 'coursesPackages/requestLoading'
        }),
        coursePackagesTableHead
    },
    created () {
        this.setCourseStep(2);
        
        if (this.list.length <= 0) {
            this.fetchList({
                id: this.$route.params.course_id
            })
            .then(() => {
                this.packages = this.list
            })
        } else {
            this.packages = this.list
        }
    },
    methods: {
        ...mapActions({
            fetchList   : 'coursesPackages/PACKAGES_GET_LIST',
            create      : 'coursesPackages/PACKAGES_CREATE',
            update      : 'coursesPackages/PACKAGES_UPDATE',
            updateSort  : 'coursesPackages/PACKAGES_UPDATE_SORT',
            remove      : 'coursesPackages/PACKAGES_DELETE'
        }),
        ...mapMutations({
            changeSkip: 'coursesPackages/PACKAGES_CHANGE_SKIP'
        }),
        changePopupAction (item, status = false) {
            if (!item && status) {
                this.resetContent();
                this.showAction = true;
            }
            
            if (!item && !status) {
                this.showAction = false;
                this.resetContent();
            }
            
            if (item && status) {
                this.showAction = true;
                this.setContent(item);
            }
        },
        changePopupDelete (item) {
            this.removingItem = item;
            this.removingItem.course_id = this.$route.params.course_id;
            this.showPopupDelete = true;
        },
        deletePackage () {
            this.remove(this.removingItem).then(() => {
                this.$toasted.success(this.$t('success_deleted'));
                this.showPopupDelete = false;
            })
        },
        resetContent () {
            Object.keys(this.payload).forEach(e => {
                this.payload[e] = ''
            });
            
            this.typeDialog = 'create';
            
            setTimeout(() => {
                this.$refs.form.reset()
            }, 10)
        },
        setContent (item) {
            const obj = {};
            
            console.log('setContent:');
            
            Object.entries(item).forEach(e => {
                //obj[camelCase(e[0])] = e[1];
                
                if(e[0] == 'is_dip'){
                    obj[camelCase(e[0])] = e[1] == 1;
                }else{
                    obj[camelCase(e[0])] = e[1];
                }
                
                console.log(obj[camelCase(e[0])]);
            });
            
            this.typeDialog = 'update';
            
            Object.assign(this.payload, obj)
        },
        createRequestPayload () {
            const data = {};
            
            console.log('createRequestPayload:');
            
            Object.entries(this.payload)
            .forEach(e => {
                data[snakeCase(e[0])] = e[1];
                
                console.log(e[0], e[1]);
            });
            
            data.course_id = this.$route.params.course_id;
            
            return data;
        },
        changeSortPackages: debounce(function (e) {
            if (e.hasOwnProperty('moved')) {
                const sortsList = this.packages.map(e => e.id);
                
                const formData = {
                    course_id   : this.$route.params.course_id,
                    packages    : sortsList
                }
                
                this.updateSort(formData)
                .then(() => {
                    this.$toasted.success(this.$t('success_change_position'))
                })
            }
        }, 2000),
        submit () {
            this.$refs.form.validate();
            
            if (this.valid) {
                if (this.typeDialog === 'update') {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'));
                        this.showAction = false;
                        this.resetContent();
                    });
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'));
                        this.showAction = false;
                        this.resetContent();
                    });
                }
            }
        },
        back () {
            this.goToStep('courses.info', 1)
        }
    }
}
